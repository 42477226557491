<template>
	<div id="layout-body">
		<router-view> </router-view>
		<back-top></back-top>
	</div>
</template>

<script>
import backTop from "@/components/back-top";

export default {
	name: "layout-body",
	data() {
		return {
			minHeight: 600,
			isFocus: true,
			progress: 0,
		};
	},
	computed: {
	},
	watch: {
	},
	components: { backTop },
	created() {

	},
	methods: {

	},
};
</script>

<style scoped lang="less">
#layout-body {
	/* header = 80px */
	min-height: -moz-calc(100vh - 80px);
	min-height: -webkit-calc(100vh - 80px);
	min-height: calc(100vh - 80px);

	.el-progress {
		position: fixed;
		right: calc(3rem - 10px);
		bottom: 2rem;
	}
}
</style>
